import React from 'react'
import PropTypes from 'prop-types'
// import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'

import { getHeaderSidebarMenu } from '@shared/libs/menu'
import {
  makeAuthorSchema,
  makeBreadcrumbSchema,
  makeFAQSchema,
  makeLiveBlogSchema,
  makeMatchDetailArticleSchema,
  makeMatchDetailSchema,
  makeMediaOrganizationSchema,
  makeOrganizationSchema,
  makePersonSchema,
  makeReelSchema,
  makeSchema,
  makeSeriesSchema,
  makeSiteNavigationSchema,
  makeWebPageSchema,
  makeWebSiteSchema,
  makeWebStoriesSchema
} from '@shared/libs/schems'
import { getCurrentProps } from '@shared/libs/current-props'
import { DOMAIN, S3_PREFIX } from '@shared/constants'
import { convertDateToISTWithFormate, dateCheck } from '@shared/utils'
import { allRoutes } from '@shared/constants/allRoutes'

function AllSchema({ data, matchDetail, scoreCard = [], location }) {
  // const isAmp = useAmp()
  const { isWebStories } = getCurrentProps()
  const router = isWebStories ? location : useRouter() || location
  const [url] = router?.asPath?.split('?')
  const nav = url?.split('/')?.filter((x) => x)
  const sliderData = getHeaderSidebarMenu()
  const scoreCardData = scoreCard?.filter((s) => (s.sStatusStr === 'scheduled' || s.sStatusStr === 'live') && s)
  const articleSchema = makeSchema(data, nav)

  function getMatchDetailSchema() {
    function getScript(seo) {
      return (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(makeMatchDetailSchema(seo, matchDetail))
          }}
        />
      )
    }
    if (nav.includes('live-scores') && nav?.length === 2) {
      return matchDetail ? getScript(data?.oSeo) : null
    } else if (nav?.includes('full-scorecard')) {
      return matchDetail ? getScript({ ...data?.oSeo, sSlug: `${data?.oSeo?.sSlug}/full-scorecard` }) : null
    } else return null
  }

  return (
    <>
      {url === allRoutes.reels && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              makeReelSchema(data)
            )
          }}
        />
      )}
      {url === '/ipl-orange-cap/' && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              makeFAQSchema([
                {
                  '@type': 'Question',
                  name: 'What is the Orange Cap?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'Orange Cap is the recognition award for the highest run-scorer in the tournament.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who is the Orange Cap holder in IPL 2024?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: "RCB's veteran batter Virat Kohli won the Orange Cap with 741 runs."
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who won the first Orange Cap in IPL history?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'The first Orange Cap winner in IPL 2008 was Shaun Marsh from the Kings XI Punjab team.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who has won the most Orange Caps in IPL history?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'Australian opening batter David Warner has won the Orange Cap most times in IPL history, winning the cap three times so far. The southpaw finished as the leading run scorers of the 2015, 2017 and 2019 editions of the tournament playing for Sunrisers Hyderabad.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who has scored the most runs in a single IPL season?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: "Royal Challengers Bengaluru's batter Virat Kohli holds the record for most runs in an IPL season as he scored 973 runs in the 2016 edition of the tournament."
                  }
                },
                {
                  '@type': 'Question',
                  name: 'What is the price of the Orange Cap winner in IPL 2024?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'The prize money for the Orange Cap winner in IPL 2024 is 15 lakhs.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Which team holds the record for the highest total in IPL history?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'Sunrisers Hyderabad scored 287/3 against Royal Challengers Bangalore.'
                  }
                }
              ])
            )
          }}
        />
      )}
      {url === '/ipl-purple-cap/' && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              makeFAQSchema([
                {
                  '@type': 'Question',
                  name: 'What is the purple cap in IPL for?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: "The Purple Cap is presented to the bowler who ends a season's highest wicket-taker."
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who is having Purple Cap in IPL 2024?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'Punjab Kings (PBKS) pacer Harshal Patel won the Purple Cap race in IPL 2024 with 24 wickets.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who has the most Purple Cap in IPL history?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'Bhuvneshwar Kumar and Dwayne Bravo are the only bowlers to have won the Purple Cap two times in IPL history.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who won the 2008 IPL Purple Cap?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'In 2008, Sohail Tanvir, playing for Rajasthan Royals, took 22 wickets in 11 matches and won the Purple Cap.'
                  }
                },
                {
                  '@type': 'Question',
                  name: 'Who is the highest wicket-taker in IPL history?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: 'Yuzvendra Chahal, with 205 wickets, holds the record for the highest number of wickets in IPL history.'
                  }
                }
              ])
            )
          }}
        />
      )}
      {data?.oSeo?.eType === 'ar' && data?.iEventId && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(makeLiveBlogSchema(data, data.oLiveArticleEvent, data.oLiveArticleList)) }}
        />
      )}
      {data?.oSeo?.eType === 'ma' && nav.includes('live-scores') && nav?.length === 2 && (
        <>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeMatchDetailArticleSchema(data)) }} />
        </>
      )}
      {articleSchema?.['@context'] && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(articleSchema) }} />
      )}
      {getMatchDetailSchema()}
      {scoreCardData?.length > 0 &&
        makeSeriesSchema(scoreCardData)?.map((j, i) => {
          return <script key={`match${i}`} type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(j) }} />
        })}
      {data?.oSeo?.eType === 'p' && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makePersonSchema(data)) }} />
      )}
      {data?.oSeo?.eType === 'ws' && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeWebStoriesSchema(data)) }} />
      )}
      {data?.oSeo?.eType === 'ad' && ( // author
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeAuthorSchema(data)) }} />
      )}

      {nav?.length > 0 && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeBreadcrumbSchema(nav, false)) }} />
      )}

      {data?.oSeo?.eType === 'au' && !data?.oSeo?.eSubType && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: `${data?.sTitle || data?.sName} Player Auction Live`,
              description: data?.oSeo?.sDescription,
              image: data?.oSeo?.oFB?.sUrl ? `${S3_PREFIX}${data?.oSeo?.oFB?.sUrl}` : 'https://www.crictracker.com/images/CricTracker-Facebook-Preview.jpg',
              startDate: convertDateToISTWithFormate(dateCheck(data?.oAuctionSeries?.dStartDate)),
              endDate: convertDateToISTWithFormate(dateCheck(data?.oAuctionSeries?.dEndDate)),
              eventStatus: 'https://schema.org/EventMovedOnline',
              eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
              location: {
                '@type': 'VirtualLocation',
                url: `${DOMAIN}${data?.oSeo?.sSlug}`
              }
            })
          }}
        />
      )}
      {/* Web page schema for all pages */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeWebPageSchema(data)) }} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(makeSiteNavigationSchema(sliderData, false)) }}
      />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeWebSiteSchema()) }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeOrganizationSchema()) }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeMediaOrganizationSchema()) }} />
    </>
  )
}
AllSchema.propTypes = {
  data: PropTypes.object,
  matchDetail: PropTypes.object,
  scoreCard: PropTypes.array,
  location: PropTypes.object
}
export default AllSchema
